import React from "react"

import { Base } from "../components/layout"
import PolicySection from "../components/page-sections/PolicySection"
import SEO from "../components/seo"

export const Head = () => <SEO title="Syrona Health | Modern Slavery Statement" />

const ModernSlaveryStatementPage: React.FC = () => (
  <Base>
    <PolicySection policy="Modern Slavery Statement" updated={updated} intro={intro} sections={sections} />
  </Base>
)

export default ModernSlaveryStatementPage

const updated = "07 Jan 2025"

const intro = <></>

const sections = [
  {
    id: "",
    heading: "Introduction",
    body: <>
      <p className="mt-4">
        This statement is made pursuant to Section 54 of the Modern Slavery Act 2015. It constitutes Syrona Health's slavery and human trafficking statement.
      </p>
    </>,
  },
  {
    id: "",
    heading: "Our Organisation",
    body: <>
      <p className="mt-4">
        Syrona Health provides cutting-edge solutions to enhance the health and wellbeing of individuals through tailored digital healthcare and workplace wellness services. We collaborate with healthcare practitioners, expert organisations, and progressive companies to improve health outcomes and support employees during significant life transitions.
      </p>
      <p className="mt-4">
        Our workforce is predominantly based in the UK and directly employed. Staff roles at Syrona Health are generally not in categories deemed vulnerable to modern slavery. However, we prioritise implementing robust policies and due diligence procedures to ensure our suppliers meet ethical standards.
      </p>
    </>
  },
  {
    id: "",
    heading: "Our Supply Chain",
    body: <>
      <p className="mt-4">
        Syrona Health is committed to continuously improving practices to identify and mitigate risks of slavery and human trafficking within its business and supply chains. We operate with integrity and hold ourselves to the highest ethical standards in all our business relationships.
      </p>
      <p className="mt-4">
        Our supply chain primarily includes global technology providers, healthcare service providers, and other professional organisations.
      </p>
    </>
  },
  {
    id: "",
    heading: "Policies",
    body: <>
      <p className="mt-4">
        Syrona Health has implemented several policies to address the risk of modern slavery and uphold ethical standards within our operations and supply chains. These include:
      </p>
      <ul className="list-inside list-disc">
        <li className="list-item">
          <strong>Code of Conduct</strong>: Sets out ethical expectations for all Syrona Health employees.
        </li>
        <li className="list-item">
          <strong>Whistleblowing Policy</strong>: Provides an anonymous reporting channel to raise concerns about unethical practices, including modern slavery, trafficking, child labour, and forced labour.
        </li>
        <li className="list-item">
          <strong>Information Security Risk Assessments</strong>: Considers modern slavery and trafficking risks as part of our ISO 27001 compliance framework.
        </li>
      </ul>
    </>
  },
  {
    id: "",
    heading: "Due diligence",
    body: <>
      <p className="mt-4">
        Syrona Health undertakes due diligence processes to monitor and mitigate risks associated with modern slavery in its supply chain. These processes include:
      </p>
      <ul className="list-inside list-disc">
        <li className="list-item">
          <strong>Supplier Risk Assessment</strong>: Identifying suppliers deemed to pose a higher risk of modern slavery.
        </li>
        <li className="list-item">
          <strong>Modern Slavery Due Diligence Questionnaire</strong>: High-risk suppliers are required to complete this questionnaire, which evaluates governance, policies, training, and supply chain management processes.
        </li>
        <li className="list-item">
          <strong>Supply Chain Transparency</strong>: Suppliers must annually disclose details of the supply chains they intend to use for providing goods or services to Syrona Health.
        </li>
      </ul>
      <p className="mt-4">
        A dedicated team at Syrona Health assesses the information provided by suppliers. Failure to meet our standards or provide the required information may result in actions such as suspension or termination of the supplier relationship.
      </p>
    </>
  },
  {
    id: "",
    heading: "Training",
    body: <>
      <p className="mt-4">
        Syrona Health provides training and guidance to employees responsible for managing supply chains, helping them identify and address modern slavery risks. This statement is shared annually with all staff to ensure awareness and compliance.
      </p>
    </>
  },
  {
    id: "",
    heading: "Looking Ahead",
    body: <>
      <p className="mt-4">
        Syrona Health is committed to enhancing its policies and practices as the organisation grows. We will continue to develop measures to prevent, identify, and mitigate risks of modern slavery and human trafficking within our operations and supply chains.
      </p>
    </>
  },
  {
    id: "",
    heading: "Approval",
    body: <>
      <p className="mt-4">
        This statement has been formally approved by the Directors of Syrona Health and signed on their behalf.
      </p>
      <p className="mt-4">
        This policy will be reviewed annually by the CEO, who is responsible for implementing this statement to ensure that Syrona Health and its supply chains remain free of slavery and human trafficking.
      </p>
    </>
  },
]
